/* Apply to all elements */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: 100dvh; /* Ensure full height for body and html */
  width: 100vw;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100dvh; /* Ensure the root div spans full height */
  width: 100vw;
}

html, body, #root {
  overflow-x: hidden;
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

body {
  background-color: rgb(10, 10, 12);
  font-family: 'Instrument Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  font-family: 'Instrument Sans';
}