/* Main container */
.landing {
  padding: 100px 20%;
  width: 100%;
  margin: 0 auto;
  font-family: 'Instrument Sans';
  background-color: rgb(22, 22, 24);
}

.cta-button {
  padding: 20px 25px;
  background-color: #aaaaff;
  color: #eeeeff;
  text-decoration: none;
  font-weight: bold;
  border-radius: 5rem;
  transition: background-color 0.5s ease;
}

.cta-button:hover {
  background-color: #eeeeff;
  color: #aaaaff;
}

@media (max-width: 768px) {
  .landing {
    padding: 100px 15%;
  }
}
