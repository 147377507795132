/* Main container */
.how-it-works {
  padding: 100px 20%;
  width: 100%;
  margin: 0 auto;
  font-family: 'Instrument Sans';
  background-color: rgb(22, 22, 24);
}

/* Title */
.title {
  font-size: 4rem;
  text-align: center;
  margin-bottom: 100px;
  color: rgb(242, 242, 247);
  font-family: 'Instrument Sans';
}

/* Section Titles */
.subtitle {
  font-size: 2rem;
  margin-bottom: 10px;
  color: rgb(242, 242, 247);
}

/* Step Titles */
.step-title {
  font-size: 1.8rem;
  color: rgb(242, 242, 247);
}

/* Paragraph Text */
.paragraph {
  font-size: 1.1rem;
  line-height: 1.6;
  color: rgb(242, 242, 247);
  margin-bottom: 5px;
  margin-top: 30px;
}

/* Lists */
.list {
  list-style: none;
  padding-left: 20px;
}

.list li {
  font-size: 1.1rem;
  color: rgb(200, 200, 247);
  margin-top: 20px;
  margin-bottom: 20px;
}

/* Section Container */
.section {
  margin-top: 100px;
  margin-bottom: 100px;
}

/* Step Section with background */
.step-section {
  padding: 50px 40px;
  background-color: rgb(30, 30, 34);
  border-radius: 10px;
  margin-bottom: 20px;
}

/* Link Styling */
.link {
  color: #ff7bff;
  text-decoration: none;
  font-weight: bold;
}

.link:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .how-it-works {
    padding: 100px 15%;
  }
  .title {
    font-size: 2.5rem;
  }
  .subtitle {
    font-size: 1.5rem;
  }
  .step-title {
    font-size: 1.2rem;
  }
  .paragraph {
    font-size: 1.0rem;
  }
  .list li {
    font-size: 1.0rem;
  }
}
